import { NavLink } from "react-router-dom";

import style from "./navigation.module.css";

const Logo = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M56.1184 11.1846C48.751 2.8585 15.8119 -0.572785 4.14651 9.29543C-7.51884 19.1636 8.75127 53.0855 12.435 55.8609C15.5812 58.2313 16.7128 58.8022 19.0798 57.5734C18.3427 57.9473 17.7249 58.1438 17.1249 58.163C17.0617 57.7798 17.0398 57.3612 17.0164 56.9124C16.9454 55.5556 16.86 53.9224 15.5749 52.1548C13.9345 49.8984 12.3137 49.226 10.9933 48.6784C9.98055 48.2583 9.14459 47.9115 8.61227 46.9794C7.70878 45.3974 5.80571 32.1616 7.32018 22.1134C8.14481 19.1206 9.35442 16.653 10.975 15.0814C17.7319 8.52907 46.4763 6.14116 56.7755 12.1735C58.0583 13.9359 58.9176 16.2367 59.42 18.8307C58.8475 15.7484 57.7831 13.066 56.1184 11.1846ZM40.017 52.0733C36.8415 52.391 33.7417 52.4673 31.3547 52.526H31.3546C29.6359 52.5683 28.2868 52.6015 27.545 52.7092C28.299 52.6076 29.6229 52.5749 31.2973 52.5335C33.6989 52.4741 36.8214 52.3969 40.017 52.0733Z"
      fill="#616E43"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48.015 53.2381C67.1205 53.2381 67.9489 8.92099 56.6019 3.11405C45.2549 -2.69289 16.522 5.29149 10.387 13.2383C4.25206 21.185 7.31948 44.7187 8.54563 46.8584C8.55515 46.875 8.56478 46.8915 8.5745 46.9077C7.65717 45.0346 5.8265 32.0236 7.32018 22.1134C8.14481 19.1206 9.35442 16.653 10.975 15.0814C17.7319 8.52907 46.4763 6.14116 56.7755 12.1735C63.2545 21.0746 58.9303 43.7116 52.3817 48.4743C47.3518 52.1323 37.2092 52.3819 31.3547 52.526C29.3552 52.5752 27.8558 52.6121 27.2312 52.7683C25.5865 53.1794 24.6311 53.8662 23.1639 54.921L23.1638 54.921C22.4421 55.4398 21.5965 56.0477 20.4842 56.7555C19.0571 57.6637 18.0688 58.1328 17.1249 58.163C17.0617 57.7798 17.0398 57.3612 17.0164 56.9124C16.9606 55.8459 16.8959 54.6088 16.2467 53.2698C16.8328 54.5491 16.8949 55.7318 16.9487 56.7564C17.0378 58.4543 17.1041 59.718 19.4942 60.2677C21.0582 60.6274 22.732 59.7923 25.1517 58.5851C29.3746 56.4782 35.869 53.2381 48.015 53.2381Z"
      fill="#1D3D59"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.975 15.0814C9.35442 16.653 8.14481 19.1206 7.32018 22.1135C5.80571 32.1616 7.70878 45.3975 8.61227 46.9795C9.14459 47.9116 9.98055 48.2583 10.9933 48.6784C12.3137 49.2261 13.9345 49.8984 15.5749 52.1548C16.86 53.9224 16.9454 55.5556 17.0164 56.9124C17.0398 57.3612 17.0617 57.7798 17.1249 58.163C18.0688 58.1329 19.0571 57.6637 20.4842 56.7556C21.5965 56.0477 22.4421 55.4399 23.1638 54.9211L23.1639 54.921C24.6311 53.8663 25.5865 53.1795 27.2312 52.7683C27.8558 52.6121 29.3552 52.5752 31.3547 52.526C37.2092 52.382 47.3518 52.1324 52.3817 48.4743C58.8537 43.7674 63.153 21.6023 56.9986 12.4918C58.179 13.2477 59.0821 14.127 59.6369 15.1372C64.8529 24.6342 64.1478 53.087 57.0884 57.682C50.0291 62.277 20.5673 66.5663 12.588 58.2947C4.60863 50.0231 3.07504 22.5639 10.747 15.1372C12.4773 13.4623 15.6486 12.0598 19.5827 11.001C15.754 12.0537 12.6715 13.4363 10.975 15.0814Z"
      fill="#EABA20"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.125 58.1629C17.3017 59.2346 17.8015 60.0297 19.5623 60.4361C21.1265 60.797 22.8006 59.9589 25.2205 58.7475C29.4439 56.6332 35.9392 53.3816 48.0867 53.3816C56.5414 53.3816 61.4173 44.6746 63.2432 34.461C63.2239 26.7641 62.0765 19.1689 59.8355 15.0813C59.2164 13.9522 58.1635 12.9864 56.7756 12.1735C63.2546 21.0746 58.9304 43.7116 52.3818 48.4742C47.3519 52.1323 37.2093 52.3819 31.3548 52.526C29.3553 52.5752 27.8559 52.6121 27.2313 52.7682C25.5866 53.1794 24.6312 53.8662 23.164 54.9209L23.1639 54.921C22.4422 55.4398 21.5966 56.0477 20.4843 56.7555C19.0572 57.6637 18.0689 58.1328 17.125 58.1629Z"
      fill="#E17E23"
    />
    <defs></defs>
  </svg>
);

const Logotype = () => (
  <svg
    width="80"
    height="20"
    viewBox="0 0 480 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M367.264 19.0686C352.851 2.78046 288.413 -3.9321 265.592 15.3729C242.772 34.6779 274.6 101.038 281.807 106.468C288.09 111.202 290.266 112.267 295.099 109.663C293.524 110.495 292.23 110.931 290.976 110.971C290.853 110.222 290.81 109.403 290.764 108.525C290.625 105.871 290.458 102.676 287.944 99.2179C284.735 94.8036 281.564 93.4884 278.981 92.417C277 91.5952 275.365 90.9169 274.323 89.0934C272.555 85.9981 268.832 60.099 271.797 40.4411C273.41 34.5896 275.775 29.765 278.945 26.6919C292.163 13.8737 348.394 9.2023 368.543 21.0031C371.189 24.6384 372.914 29.4451 373.871 34.8602C372.791 28.4808 370.67 22.9177 367.264 19.0686ZM336.293 99.0038C329.895 99.6713 323.616 99.8258 318.813 99.944C315.384 100.028 312.708 100.094 311.276 100.315C312.734 100.106 315.363 100.041 318.707 99.9587C323.537 99.8393 329.859 99.683 336.293 99.0038Z"
      fill="#616E43"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M351.409 101.337C388.785 101.337 390.405 14.6405 368.207 3.28045C346.009 -8.07956 289.8 7.54013 277.798 23.0862C265.796 38.6324 271.797 84.6708 274.196 88.8567C274.211 88.8833 274.226 88.9096 274.242 88.9358C272.444 85.2083 268.878 59.7986 271.797 40.441C273.411 34.5895 275.776 29.765 278.945 26.6919C292.164 13.8737 348.395 9.2023 368.544 21.0031C381.219 38.4161 372.759 82.7006 359.948 92.0177C350.108 99.1739 330.267 99.6622 318.813 99.944C314.902 100.04 311.969 100.112 310.747 100.418C307.529 101.222 305.66 102.566 302.79 104.629C301.378 105.644 299.724 106.833 297.548 108.218C294.756 109.994 292.823 110.912 290.977 110.971C290.853 110.222 290.811 109.403 290.765 108.525C290.657 106.458 290.531 104.063 289.294 101.471C290.41 103.946 290.53 106.235 290.634 108.22C290.809 111.542 290.938 114.014 295.614 115.089C298.674 115.793 301.948 114.159 306.682 111.797C314.943 107.676 327.648 101.337 351.409 101.337Z"
      fill="#1D3D59"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M278.945 26.6919C275.776 29.765 273.411 34.5895 271.798 40.441C268.833 60.0989 272.556 85.9981 274.324 89.0934C275.365 90.9168 277.001 91.5952 278.982 92.417C281.565 93.4884 284.736 94.8036 287.945 99.2179C290.459 102.676 290.626 105.871 290.765 108.525V108.525C290.811 109.403 290.854 110.222 290.977 110.971C292.823 110.912 294.757 109.994 297.548 108.218C299.724 106.833 301.378 105.644 302.79 104.629C305.66 102.566 307.529 101.222 310.747 100.418C311.969 100.112 314.902 100.04 318.814 99.944C330.267 99.6622 350.109 99.1739 359.948 92.0177C372.61 82.8089 381.021 39.4411 368.977 21.6212C371.29 23.101 373.059 24.8227 374.146 26.8009C384.35 45.3797 382.97 101.041 369.16 110.031C355.35 119.02 297.715 127.411 282.105 111.229C266.495 95.0475 263.495 41.3296 278.503 26.8011C281.86 23.5514 287.99 20.826 295.598 18.7608C288.195 20.8137 282.237 23.5003 278.945 26.6919Z"
      fill="#EABA20"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M290.977 110.971C291.322 113.068 292.3 114.623 295.745 115.418C298.805 116.125 302.08 114.485 306.814 112.115C315.076 107.979 327.782 101.618 351.546 101.618C368.085 101.618 377.623 84.5875 381.196 64.6089C381.159 49.5499 378.914 34.6891 374.529 26.6917C373.318 24.4828 371.258 22.5933 368.543 21.0031C381.218 38.416 372.759 82.7006 359.948 92.0177C350.108 99.1739 330.266 99.6622 318.813 99.944C314.901 100.04 311.968 100.112 310.746 100.418C307.529 101.222 305.66 102.566 302.789 104.629L302.789 104.629L302.789 104.63C301.377 105.644 299.723 106.834 297.547 108.218C294.756 109.994 292.823 110.912 290.977 110.971Z"
      fill="#E17E23"
    />
    <path
      d="M0 118.193V1.80723H19.8341V55.3012L69.6922 1.80723H92.6197L49.4942 48.7952L94.4393 118.193H73.3315L37.1207 61.2651L19.8341 79.6988V118.193H0Z"
      fill="#1D3D59"
    />
    <path
      d="M113.727 118.193V1.80723H133.562V118.193H113.727Z"
      fill="#1D3D59"
    />
    <path
      d="M156.553 118.193V1.80723H208.777C217.754 1.80723 225.153 3.49397 230.976 6.86747C236.799 10.241 241.106 14.8795 243.896 20.7831C246.686 26.5663 248.081 33.1928 248.081 40.6626C248.081 52.2289 244.442 61.506 237.163 68.494C230.006 75.4819 220.301 78.9759 208.049 78.9759H176.387V118.193H156.553ZM176.387 63.4337H204.41C212.295 63.4337 218.239 61.3855 222.242 57.2892C226.245 53.1928 228.247 47.6506 228.247 40.6626C228.247 33.1928 226.306 27.4096 222.424 23.3133C218.663 19.2169 212.962 17.1687 205.319 17.1687H176.387V63.4337Z"
      fill="#1D3D59"
    />
    <path
      d="M433.781 120C418.617 120 406.426 118.072 397.206 114.217V96.8675C402.423 98.7952 408.003 100.361 413.947 101.566C420.013 102.771 426.017 103.373 431.961 103.373C441.302 103.373 448.278 102.289 452.887 100.12C457.618 97.9518 459.984 93.6145 459.984 87.1084C459.984 83.1325 459.013 79.9398 457.072 77.5301C455.132 75.1205 451.917 73.0723 447.428 71.3855C442.94 69.5783 436.753 67.7108 428.868 65.7831C416.252 62.5301 407.396 58.4337 402.301 53.494C397.206 48.4337 394.659 41.506 394.659 32.7108C394.659 22.4699 398.419 14.4578 405.941 8.67471C413.462 2.89157 424.683 0 439.604 0C446.397 0 452.766 0.481929 458.71 1.44579C464.776 2.40964 469.507 3.49398 472.903 4.6988V22.0482C463.684 18.5542 453.494 16.8072 442.333 16.8072C433.599 16.8072 426.806 17.9518 421.953 20.241C417.101 22.5301 414.675 26.6868 414.675 32.7108C414.675 36.2048 415.524 39.0361 417.222 41.2048C418.921 43.3735 421.832 45.241 425.957 46.8072C430.202 48.3735 436.025 50.1205 443.425 52.0482C453.009 54.4578 460.409 57.3494 465.625 60.7229C470.962 63.9759 474.662 67.8313 476.725 72.2892C478.908 76.6265 480 81.5663 480 87.1084C480 97.3494 476.179 105.422 468.536 111.325C460.894 117.108 449.309 120 433.781 120Z"
      fill="#1D3D59"
    />
  </svg>
);

const HomeLink = ({ expanded }) => {
  return (
    <>
      <NavLink to="/" className={style.homeLink}>
        {expanded ? <Logotype /> : <Logo />}
      </NavLink>
    </>
  );
};

export default HomeLink;
